import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import ProductsGrid from "../components/productsGrid";

export default function Template({ data }) {
  const { markdownRemark, allProducts = {} } = data;
  const { frontmatter, html } = markdownRemark;
  const { edges: products = {} } = allProducts;

  const breadcrumbs = [
    { name: "Home", href: "/" },
    {
      name: "Shop",
      href: "/shop/",
    },
    { name: frontmatter.title },
  ];

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          id="cljs"
          src="https://hydrorace.cartloom.com/cl4/cart.js"
        />
      </Helmet>
      <Layout
        breadcrumbs={breadcrumbs}
        pageTitle={frontmatter.title}
        preambleText={frontmatter.preambleText}
        metaDescription={frontmatter.metaDescription}
      >
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <ProductsGrid products={products} />
      </Layout>
    </>
  );
}

export const shopHomeQuery = graphql`
  query($id: String!, $slug: String!, $slugRegex: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        preambleText
        metaDescription
      }
    }
    allProducts: allMarkdownRemark(
      filter: { fields: { slug: { regex: $slugRegex, ne: $slug } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            price
            cartloom {
              cartloomId
              buttonLabel
            }
          }
        }
      }
    }
  }
`;
