import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import Img from "gatsby-image";
import { Button } from "./button";
import { focusState } from "../tokens/a11y";

const ProductsGridWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

  margin-top: 2rem;
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem 1rem 0;
  border-radius: 1rem;
  text-align: center;
  background-color: ${props => props.theme.palette.grey};
`;

const ImageLink = styled(GatsbyLink)`
  display: block;
  margin: -1rem -1rem 0;
  padding-bottom: 1rem;
  border-radius: 1rem;

  &:focus {
    ${focusState};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding-bottom: 1rem;

  > form {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
  }
`;

const BuyButton = styled(Button)`
  margin-top: 0.5rem;
`;

const Link = styled(Button)`
  margin-top: 0.5rem;
`;

const ImageContainer = styled.div`
  border-radius: 1rem;
  overflow: hidden;
`;

const Title = styled.h2`
  margin: 0.5em 1rem 0;
`;

const ProductsGrid = ({ products }) => (
  <ProductsGridWrapper>
    {products.map(({ node: product }) => (
      <ProductWrapper key={product.id}>
        <ImageLink to={product.fields.slug}>
          {product.frontmatter.image &&
            product.frontmatter.image.childImageSharp &&
            product.frontmatter.image.childImageSharp.fluid && (
              <ImageContainer>
                <Img fluid={product.frontmatter.image.childImageSharp.fluid} />
              </ImageContainer>
            )}
          <Title>{product.frontmatter.title}</Title>
        </ImageLink>
        <Body>
          <Link as={GatsbyLink} to={product.fields.slug} secondary="true">
            View details
          </Link>
          {product.frontmatter.cartloom &&
            product.frontmatter.cartloom.map(({ cartloomId, buttonLabel }) => (
              <form
                key={cartloomId}
                action={`https://hydrorace.cartloom.com/cart/add/${cartloomId}`}
                method="post"
                className="cartloom-addtocart"
              >
                <input type="hidden" name="cart" value="add" />
                <input type="hidden" name="pid" value={cartloomId} />
                <input
                  type="hidden"
                  name="cost"
                  value={product.frontmatter.price}
                />
                <BuyButton type="submit">{buttonLabel}</BuyButton>
              </form>
            ))}
        </Body>
      </ProductWrapper>
    ))}
  </ProductsGridWrapper>
);

export default ProductsGrid;
